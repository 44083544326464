<template>
    <div>
        <form @submit.prevent="submit()" ref="form" :class="loading && !hideConfirmButton ? 'whirl' : ''">

            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>{{$t('wallet.view.action.make_withdraw.method.bank_transfer.receiver')}}</label>
                        <div class="border border-primary p-3">
                            <p class="mb-1" v-if="model.receiver.type === 'company'">{{ model.receiver.company }}</p>
                            <p class="mb-1" v-else>{{ model.receiver.firstname }} {{ model.receiver.lastname }}</p>
                            <p class="mb-1">{{ model.receiver.street }} {{ model.receiver.house_number }}</p>
                            <p class="mb-1">{{ model.receiver.zip }} {{ model.receiver.town }}</p>
                            <p class="mb-1">{{ model.receiver.country }}</p>
                        </div>
                    </div>
                </div>
            </div>

          <b-form-group>
            <label>{{$t('wallet.view.action.make_withdraw.method.bank_transfer.bank_name')}}</label>
            <b-input type="text" v-model="model.bank_name"></b-input>
          </b-form-group>

            <b-row>
                <b-col sm="4">
                    <b-form-group class="mb-0">
                        <label>Bank Zip</label>
                        <b-input type="text" v-model="model.bank_zip"></b-input>
                    </b-form-group>
                </b-col>
                <b-col sm="8">
                    <b-form-group class="mb-0">
                        <label>Bank Town</label>
                        <b-input type="text" v-model="model.bank_town"></b-input>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-form-group label="Bank Street" class="mb-0">
              <b-input type="text" v-model="model.bank_street"></b-input>
            </b-form-group>

            <b-form-group label="Bank Country" class="mb-0">
              <b-input type="text" v-model="model.bank_country"></b-input>
            </b-form-group>

          <b-row>
            <b-col sm="6">
              <b-form-group label="BIC" class="mb-0">
                <b-input type="text" v-model="model.bic"></b-input>
              </b-form-group>

            </b-col>
            <b-col sm="6">
              <b-form-group label="Swift" class="mb-0">
                <b-input type="text" v-model="model.swift"></b-input>
              </b-form-group>
            </b-col>
          </b-row>



            <b-form-group label="Enter Routing Number" class="mb-0">
              <b-input type="text" v-model="model.routing_number"></b-input>
            </b-form-group>

            <b-form-group label="Enter Account Number" class="mb-0">
              <b-input type="text" v-model="model.account_number"></b-input>
            </b-form-group>


          <div class="text-right">
                <button class="btn btn-info" v-show="!hideConfirmButton" type="submit" :disabled="loading">{{ $t('common.button.save.label') }}</button>
            </div>
        </form>
    </div>
</template>
<script>
    import SlotEntityForm from "../../../Common/components/SlotEntityForm";

    export default {
        extends: SlotEntityForm,
        data() {
            return {
                model: {
                    bank_name: '',
                    bank_zip: '',
                    bank_town: '',
                    bank_street: '',
                    bank_country: '',
                    routing_number: '',
                    bic: '',
                    swift: ''
                },
                apiEndpoint: 'wallet/withdraw/account/international-bank',
            }
        }
    }
</script>
