<template>
    <div class="card card-data-browser card-gray">
        <div class="card-header">
            <i class="card-title-icon" :class="icon"></i> {{ title }}

            <nav class="float-right">
                <ul class="pagination mb-0">
                    <li class="page-item"><span class="page-link" @click="prevPage" href=""><i
                            class="fad fa-arrow-left"></i></span></li>
                    <li class="page-item" :class="{active: page === state.page}" v-for="page in state.pages"><span
                            class="page-link">{{page}}</span></li>
                    <li class="page-item"><span class="page-link" @click="nextPage" href=""><i
                            class="fad fa-arrow-right"></i></span></li>
                </ul>
            </nav>
        </div>
        <div class="card-body p-0" :class="loading ? 'whirl' : ''">

            <div v-show="state.data.length" class="table-wrapper">
                <table class="table table-striped table-bordered">
                    <thead>
                    <tr>
                        <th>{{ $t('wallet.component.withdraw_browser.label') }}</th>
                        <th>{{ $t('wallet.component.withdraw_browser.type') }}</th>
                        <th>{{ $t('wallet.component.withdraw_browser.status') }}</th>
                        <th>{{ $t('wallet.component.withdraw_browser.amount') }}</th>
                        <th class="text-right">{{ $t('wallet.component.withdraw_browser.created_at') }}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in state.data">
                        <td>{{ item.transaction.label }}</td>
                        <td>{{ $t('wallet.component.withdraw_browser.types.' + item.type) }}</td>
                        <td>
                            <span class="badge badge-danger" v-if="item.canceled_at">{{ $t('wallet.component.withdraw_browser.statuses.canceled.label') }}</span>
                            <span class="badge badge-warning" v-if="!item.paid_at && !item.canceled_at">{{ $t('wallet.component.withdraw_browser.statuses.waiting.label') }}</span>
                            <span class="badge badge-primary" v-if="item.paid_at && !item.canceled_at">
                                {{ $t('wallet.component.withdraw_browser.statuses.sent.label', {datetime: $moment(item.paid_at).format('L LTS')}) }}
                            </span>
                        </td>

                        <td class="text-right">
                            <formatted-number :amount="item.amount" type="fiat"/>
                        </td>
                        <td class="text-right">{{ item.created_at | moment('utc', 'L')}} <span class="d-inline-block"
                                                                                               style="width: 7em">{{ item.created_at | moment('utc', 'LTS')}}</span>
                        </td>
                        <td class="p-0 pt-1 text-center">
                            <b-btn v-if="item.paid_at" :to="{name: 'Wallet:Withdraw', params: {withdraw_label: item.transaction.label}}" variant="link">
                                <i class="fad fa-eye text-secondary" style="font-size:1.3em"></i>
                            </b-btn>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="p-3">
                    <b-row>
                        <b-col sm="4">
                            <span class="badge badge-primary"> {{ $t('wallet.component.withdraw_browser.statuses.sent.label', {datetime: ''}) }} </span>
                            <br/> {{$t('wallet.component.withdraw_browser.statuses.sent.description')}}
                        </b-col>
                        <b-col sm="4">
                            <span class="badge badge-warning">{{ $t('wallet.component.withdraw_browser.statuses.waiting.label') }}</span>
                            <br/> {{ $t('wallet.component.withdraw_browser.statuses.waiting.description') }}
                        </b-col>
                        <b-col sm="4">
                            <i class="fad fa-eye text-secondary" style="font-size: 1.3em"></i>
                            <br/> {{ $t('wallet.component.withdraw_browser.detail_view_description') }}
                        </b-col>
                    </b-row>
                </div>
            </div>
            <div v-show="!state.data.length && !loading">
                <div class="m-3 alert alert-info">
                    {{ $t('wallet.component.transactions.empty') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TableDataBrowser from '@/modules/Wallet/components/TableDataBrowser.vue';

    export default {
        extends: TableDataBrowser,
        props: ['preSelectType'],
        data() {
            return {
                endpoint: '/wallet/withdraw/list',
                title: 'Withdraws',
                icon: 'fad fa-book',
                types: [],
                selectedType: undefined
            }
        },
    }
</script>
