<template>
    <div>
        <form @submit.prevent="submit()" ref="form" :class="loading && !hideConfirmButton ? 'whirl' : ''">

          <div class="row">
              <div class="col-sm-12">
                    <div class="form-group">
                      <label>{{$t('wallet.view.action.make_withdraw.method.bank_transfer.receiver')}}</label>
                      <div class="border border-primary p-3">
                          <p class="mb-1" v-if="model.receiver.type === 'company'">{{ model.receiver.company }}</p>
                          <p class="mb-1" v-else>{{ model.receiver.firstname }} {{ model.receiver.lastname }}</p>
                          <p class="mb-1">{{ model.receiver.street }} {{ model.receiver.house_number }}</p>
                          <p class="mb-1">{{ model.receiver.zip }} {{ model.receiver.town }}</p>
                          <p class="mb-1">{{ model.receiver.country }}</p>
                      </div>
                  </div>
              </div>
          </div>

            <b-form-group label="Enter IBAN">
                <b-input type="text" v-model="model.iban" required></b-input>
            </b-form-group>

            <b-row>
                <b-col sm="6">
                    <b-form-group>
                        <label>BIC</label>
                        <b-input type="text" v-model="model.bic" required></b-input>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>{{$t('wallet.view.action.make_withdraw.method.bank_transfer.bank_name')}}</label>
                        <b-input type="text" v-model="model.bank_name" required></b-input>
                    </b-form-group>
                </b-col>
            </b-row>

            <div class="text-right">
                <button class="btn btn-info" v-show="!hideConfirmButton" type="submit" :disabled="loading">{{ $t('common.button.save.label') }}</button>
            </div>
        </form>
    </div>
</template>
<script>
    import SlotEntityForm from "../../../Common/components/SlotEntityForm";

    export default {
        extends: SlotEntityForm,
        data() {
            return {
                model: {
                    iban: '',
                    bank_name: '',
                    bic: ''
                },
                apiEndpoint: 'wallet/withdraw/account/bank',
            }
        }
    }
</script>
